<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">用户列表</h4>
            <div class="row mt-4">
              <el-button type="primary" @click="AddAdminSwitch = true">新增账号</el-button>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" per-page=0 :sort-by.sync="sortBy"
                :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter">
                <template v-slot:cell(user_img)="data">
                  <span v-html="data.value"></span>
                </template>

                <template v-slot:cell(user_of_true)="data">
                  <el-tag v-if="data.value">是</el-tag>
                  <el-tag v-else type="info">否</el-tag>
                </template>

                <template v-slot:cell(tool)="data">

                  <b-button @click="delRows(data.item)" style="margin-right: 10px;" variant="warning">删除
                  </b-button>
                  <b-button @click="EditRow(data.item)" style="margin-right: 10px;" variant="info">修改
                  </b-button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>

    <el-dialog title="添加账号" :visible.sync="AddAdminSwitch">
      <el-form :model="AddAdminForm">
        <el-form-item label-width="80px" label="账号" >
          <el-input v-model="AddAdminForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="密码" >
          <el-input v-model="AddAdminForm.password" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label-width="80px" label="昵称" >
          <el-input v-model="AddAdminForm.real_name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label-width="80px" label="手机号" >
          <el-input v-model="AddAdminForm.mobile" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="AddAdminSwitch = false">取 消</el-button>
        <el-button type="primary" @click="SubmitAddAdmin">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改账号" :visible.sync="EditAdminSwitch">
      <el-form :model="EditAdminForm">
        <el-form-item label-width="80px" label="账号" >
          <el-input v-model="EditAdminForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="密码" >
          <el-input v-model="EditAdminForm.password" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label-width="80px" label="昵称" >
          <el-input v-model="EditAdminForm.real_name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label-width="80px" label="手机号" >
          <el-input v-model="EditAdminForm.mobile" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="EditAdminSwitch = false">取 消</el-button>
        <el-button type="primary" @click="SubmitEditAdmin">确 定</el-button>
      </div>
    </el-dialog>

  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  // import { tableData } from "./dataAdvancedtable";
  /**
   * Advanced table component
   */
  export default {
    page: {
      title: "用户列表",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        tableData: '',
        AddAdminSwitch:false,
        EditAdminSwitch:false,
        title: "用户列表",
        items: [
          {
            text: "首页",
            href: "/"
          },
          {
            text: "用户列表",
            active: true
          }
        ],
        filter: null,
        // 排序
        sortBy: "go_time",
        sortDesc: true,
        AddAdminForm:{
          username:"",
          password:"",
          real_name:"",
          mobile:"",
        },
        EditAdminForm:{
          username:"",
          password:"",
          real_name:"",
          mobile:"",
        },
        fields: [
          { key: "username", label: "账号" },
          { key: "password", label: "密码" },
          { key: "real_name", label: "昵称" },
          { key: "mobile", label: "手机号" },
          {
            key: "tool", label: "工具栏"
          },
        ],
        EditRowData: "",
        LogoFile: "",
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: ""
      };
    },
    computed: {
    },
    mounted() {
    },
    created() {
      this.CheckParentCompany()

      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },
    methods: {
      SubmitAddAdmin(){
        _API_.AddAdmin({
          ...this.AddAdminForm
          }).then((res) => {
            res.code == 200 ? utils.ShowMsg(res.msg, 'success') : utils.ShowMsg(res.msg, 'error')
            this.AddAdminSwitch = false
            this.CheckParentCompany()
          })
      },
      SubmitEditAdmin(){
        _API_.EditAdmin({
          ...this.EditAdminForm
          }).then((res) => {
            res.code == 200 ? utils.ShowMsg(res.msg, 'success') : utils.ShowMsg(res.msg, 'error')
            this.EditAdminSwitch = false
            this.CheckParentCompany()
          })
      },
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {

        }
      },
      EditRow(item)
      {
        console.log(item)
        this.EditAdminSwitch = true
        this.EditAdminForm = item
      },
      // 删除
      delRows(item,) {

        if (item.id == 1) {
          utils.ShowMsg('超级管理员不能删除', 'error')
        } else {
          _API_.DelAdmin({
            username: item.username,
          }).then((res) => {
            res.code == 200 ? utils.ShowMsg(res.msg, 'success') : utils.ShowMsg(res.msg, 'error')
            this.CheckParentCompany()
          })
        }

      },
      // 查看帖子
      CheckComm(Item, Editindex) {
        console.log(UserMethods)
        UserMethods.set_comm_uuid(Item.user_uuid)
        console.log(_store_.state.user)
        this.$router.push({
          name: "UserComm",
          params: { uuid: Item.user_uuid },
        });
      },
      // 查看留言
      CheckLeave(Item, Editindex) {
        this.$router.push({
          name: "UserLeave",
          params: { uuid: Item.user_uuid },
        });
      },
      // 获取数据
      CheckParentCompany() {
        _API_.AdminList({
          page: this.page.currentPage,
          size: this.page.pageSize
        }).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data
            this.page.total = res.count
          } else {
            utils.ShowMsg(res.msg, 'error')
          }

        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }
</style>